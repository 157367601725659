body {
  width: 100%;
  max-width: 100%;
}
p {
  color: #5e6c84;
}

#header {
  display: flex;
  width: 100vw;
  padding-left: 8.5vw;
  padding-right: 5vw;
  /* padding-top: 0.5vw; */
  align-items: center;
  position: static;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

#header img {
  width: 12vw;
  height: auto;
  /* height: 5vh; */
}

#nav-div {
  display: flex;
  width: 90vw;
  margin-left: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

a {
  text-decoration: none;
}

nav a {
  margin-right: 20px;
  color: slategrey;
}

#signup-link {
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  color: #6fcf97;
  margin-right: 35px;
  margin-left: 8px;
}

#signin-link {
  height: auto;
  border-radius: 5px;
  background-color: #6fcf97;
  padding: 15px;
}

.nav-link {
  height: auto;
  border-radius: 5px;
  color: #344563;
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  padding: 15px;
}

#signin-link a {
  font-size: large;
  color: white;
  text-align: center;
}

#background-image {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* Login */

.auth-component {
  display: flex;
  width: "100%";
}

.auth-component img {
  padding: 90px;
  margin-top: 5vh;
  width: 48vw;
  height: 90vh;
}

.divider {
  margin-top: 5vh;
  width: 1px;
  height: "90%";
  background-color: #e8eaed;
}

#login-button {
  width: 30vw;
}

/* Footer */
#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

#footer-hr {
  color: green !important;
  width: 100vw;
}

#footer-div {
  width: 20vw;
  margin-bottom: 10px;
}

#footer-nav-link {
  margin-top: 1vh;
  margin-bottom: 2.5vh;
  width: 30vw;
  display: flex;
  justify-content: space-evenly;
}

#footer-nav-link a {
  color: #5e6c84;
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}


@media screen and (max-width: 600px) {
  #footer-nav-link {
    width: 100vw;
  }

  #footer-div {
    width: 50vw;
  }

  #login-button {
    width: 90vw;
  }

  #header img {
    width: 25vw;
    height: 5vh;
  }

  #header {
    width: 100vw;
    /* padding-left: 8.5vw;
    padding-right: 0.1vw; */
    background-color: white;
    padding: 12px;
  }

  #header img {
    width: 80%;
    height: auto;
    /* height: 5vh; */
  }

  #signup-link {
    margin-right: "1%";
    margin-left: 8px;
  }

  .nav-link {
    margin-top: 40;
  }

  #background-image {
    background-position: center;
    background-size: cover;
  }

  body {
    width: 100%;
    max-width: 100%;
  }
}
